import React from "react"

import { Container, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import InnerLayout from "../components/inner-layout"

import MasonryGrid from "../components/masonry-grid"

const HotGlass = ({ data: { contentfulPage } }) => (
  <Layout>
    {console.log(contentfulPage)}
    <SEO title="Hot Glass" />
    <InnerLayout>
      <Container>
        <Col className="text-center mx-auto mb-5" md={8}>
          <h1>Hot Glass</h1>
          <p>
            Here we present a small selection of Tim’s current work for 2020.
            Each piece is made with the eye and skill of experience, freeblown
            with no moulds used.
          </p>
        </Col>
      </Container>
      <MasonryGrid files={contentfulPage.images} />
    </InnerLayout>
  </Layout>
)

export const query = graphql`
  {
    contentfulPage(slug: { eq: "hot-glass" }) {
      name
      slug
      images {
        ... on ContentfulProductImage {
          name
          price
          image {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default HotGlass
